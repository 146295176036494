import { createStore } from 'vuex';
import axios from 'axios';

axios.defaults.baseURL = 'http://127.0.0.1:8000';

function getCsrfToken() {
  // Retrieve CSRF token from cookies
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith('csrftoken='))
    ?.split('=')[1] || '';
}

export default createStore({
  state: {
    theme: localStorage.getItem('theme') || 'light',
    loading: false,
    error: null,
    
    // isAuthenticated: false,
    authToken: localStorage.getItem('authToken') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,

    blogs: [],
    totalBlogs: 0,
    blog: null,
    categories: [],
    tags: [],

    sessionId: null,
    sessionData: null,

    history_events: [],
    totalHistoryEvents: 0,
    nextPage: 1,
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    },

    SET_LOADING(state, status) {
      state.loading = status;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },

    // authenticate(state){
    //   const token = localStorage.getItem('authToken');
    // },
    setAuthenticationStatus(state, status) {
      state.isAuthenticated = status;
    },


    setAuthToken(state, token) {
      state.authToken = token;
      localStorage.setItem('authToken', token);
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;  // Set token for all Axios requests
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    clearAuth(state) {
      state.authToken = null;
      state.user = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];  // Remove the token from Axios headers
    },

    // Blog posts
    SET_BLOGS(state, blogs) {
      state.blogs = blogs;
    },
    APPEND_BLOG(state, blog) {
      state.blogs.push(blog);
    },
    APPEND_BLOGS(state, newBlogs) {
      // state.blogs = [...state.blogs, ...newBlogs];
      newBlogs.forEach((blog) => {
        if (!state.blogs.some((existingBlog) => existingBlog.id === blog.id)) {
          state.blogs.push(blog);
        }
      });
    },
    SET_TOTAL_BLOGS(state, total) {
      state.totalBlogs = total;
    },
    setBlog(state, blog) {
      state.blog = blog;
    },
    // Categories
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    ADD_CATEGORY(state, category) {
      state.categories.push(category);
    },
    UPDATE_CATEGORY(state, updatedCategory) {
      const index = state.categories.findIndex((cat) => cat.id === updatedCategory.id);
      if (index !== -1) {
        state.categories.splice(index, 1, updatedCategory);
      }
    },
    DELETE_CATEGORY(state, categoryId) {
      state.categories = state.categories.filter((cat) => cat.id !== categoryId);
    },
    // Tags
    SET_TAGS(state, tags) {
      state.tags = tags;
    },
    ADD_TAGS(state, newTags) {
      state.tags = [...state.tags, ...newTags];
    },
    updateTag(state, updatedTag) {
      const index = state.tags.findIndex((tag) => tag.id === updatedTag.id);
      if (index !== -1) {
        state.tags.splice(index, 1, updatedTag);
      }
    },
    deleteTag(state, id) {
      state.tags = state.tags.filter(tag => tag.id !== id);
    },

    // RPS
    setSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    setSessionData(state, sessionData) {
      state.sessionData = sessionData;
    },
    resetGame(state) {
      state.sessionId = null;
      state.sessionData = null;
    },

    // Human History
    // ADD_EVENTS(state, payload) {
    //   state.history_events = [...state.history_events, ...payload.results].sort((a, b) => new Date(b.date) - new Date(a.date)); // Ensure descending order
    //   state.totalHistoryEvents = payload.count;
    //   state.nextPage = payload.next ? state.nextPage + 1 : null;
    // },
    ADD_EVENTS(state, payload) {
      function parseDate(dateStr) {
        if (!dateStr) return -Infinity;
        
        dateStr = dateStr.trim();

        // Handle MYA
        let myaMatch = dateStr.match(/(\d+(\.\d+)?)\s*MYA/i);
        if (myaMatch) return -parseFloat(myaMatch[1]) * 1_000_000;

        // Handle BC
        let bcMatch = dateStr.match(/(\d+)(?:-(\d+))?\s*BC/i);
        if (bcMatch) {
          let start = parseInt(bcMatch[1]);
          let end = bcMatch[2] ? parseInt(bcMatch[2]) : start;
          return -(start + end) / 2;
        }

        // Handle year ranges (e.g., "1668-1744")
        let rangeMatch = dateStr.match(/(\d{3,4})-(\d{3,4})/);
        if (rangeMatch) return (parseInt(rangeMatch[1]) + parseInt(rangeMatch[2])) / 2;

        // Handle full modern dates (e.g., "Sep. 11 2001")
        let parsedDate = Date.parse(dateStr);
        if (!isNaN(parsedDate)) return new Date(parsedDate).getFullYear();

        // Handle single years
        let yearMatch = dateStr.match(/(\d{3,4})/);
        if (yearMatch) return parseInt(yearMatch[1]);

        return -Infinity;
      }

      state.history_events = [...state.history_events, ...payload.results]
        .sort((a, b) => parseDate(b.date) - parseDate(a.date)); // Sort descending
      state.totalHistoryEvents = payload.count;
      state.nextPage = payload.next ? state.nextPage + 1 : null;
    }
    //
  },
  actions: {
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      commit('setTheme', newTheme);
      document.querySelector('#app').setAttribute('data-bs-theme', newTheme);
    },

    // For authentication
    async login({ commit, dispatch }, credentials) {  // Add `dispatch` here
      try {
        const csrfToken = getCsrfToken();
  
        const response = await axios.post('/api/login/', credentials, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
        console.log('login response:',response);
        
  
        commit('setAuthToken', response.data.token);  // Save token
        await dispatch('fetchUser');  // Fetch user profile after login
      } catch (error) {
        console.error('Login error:', error.response?.data || error.message);
        throw new Error('Login failed');
      }
    },
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/api/user-profile/');

        console.log(response);
        
        commit('setUser', response.data);  // Save user profile to Vuex state
      } catch (error) {
        console.error('Failed to fetch user profile:', error.response?.data || error.message);
        commit('clearAuth');  // Clear token if fetching profile fails
      }
    },
    logout({ commit }) {
      commit('clearAuth');  // Clear token and user data
    },
    async registerUser(_, userData) {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken='))
          ?.split('=')[1];
  
        await axios.post('/api/register/', userData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
      } catch (error) {
        throw new Error('Error during registration');
      }
    },
    async updateUserProfile({ commit }, profile) {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken='))
          ?.split('=')[1];
        await axios.put('/api/user-profile/', profile, {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
        commit('setUser', profile);  // Update Vuex state
      } catch (error) {
        throw new Error('Error updating profile');
      }
    },
    async changeUserPassword(_, passwordForm) {
      try {
        const csrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('csrftoken='))
          ?.split('=')[1];
        await axios.post('/api/change-password/', passwordForm, {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        });
      } catch (error) {
        throw new Error('Error changing password');
      }
    },
    loadAuth({ commit }) {
      console.log('load auth');
      
      const token = localStorage.getItem("authToken");
      const user = JSON.parse(localStorage.getItem("user"));
      if (token && user) {
        commit("setAuthToken", token);
        commit("setUser", user);
      }
    },



    // Blog

    async fetchBlogs({ commit }, {limit, offset}) {
      console.log('fetchBlogs',);
      // console.log('limit', limit);
      // console.log('offset', offset);
      try {
        const response = await axios.get(`/api/posts/?limit=${limit}&offset=${offset}`);
        console.log('response', response);
        // const { posts, total } = response.data;
        const posts = response.data.results;
        const total = response.data.count;


        console.log('response.data', response.data.results);
        console.log('posts', posts);
        console.log('total', total);

        if (offset === 0) {
          commit('SET_BLOGS', posts);
          console.log('set to posts', posts);
        } else {
          commit('APPEND_BLOGS', posts);
          console.log('add to posts', posts);
        }
        commit('SET_TOTAL_BLOGS', total);
      } catch (error) {
        console.error('Error fetching blogs:', error.message);
      }
    },
    

    async fetchBlog({ commit }, id) {
      try {
        const response = await axios.get(`/api/posts/${id}/`);
        commit('APPEND_BLOG', response.data);
        return response.data;
      } catch (error) {
        console.error('Error fetching blog:', error.message);
        throw error;
      }
    },

    async createBlog(blogData) {
      console.log('blogData',blogData);
      
      try {
        const csrfToken = getCsrfToken();  // Get CSRF token
    
        const res = await axios.post('/api/posts/', blogData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include CSRF token
          },
          withCredentials: true,  // Ensure cookies are sent
        });
    
        // dispatch('fetchBlogs');  // Refresh blog list after creation
        return res.data;
      } catch (error) {
        console.error('Error creating blog:', error.response?.data || error.message);
      }
    },
    
    async editBlog({ dispatch }, blogData) {
      console.log('edit blog', blogData);
      
      try {
        const csrfToken = getCsrfToken();  // Get CSRF token
    
        await axios.put(`/api/posts/${blogData.id}/`, blogData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include CSRF token
          },
          withCredentials: true,  // Ensure cookies are sent
        });
    
        dispatch('fetchBlogs');  // Refresh blog list after editing
      } catch (error) {
        console.error('Error editing blog:', error.response?.data || error.message);
      }
    },


    // Categories
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get("/api/categories/");
        commit("SET_CATEGORIES", response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async addCategory({ commit }, category) {
      try {
        // const response = 
        await axios.post("/api/categories/", category);
        commit("ADD_CATEGORY", category);
      } catch (error) {
        console.error("Error adding category:", error);
      }
    },
    async updateCategory({ commit }, category) {
      try {
        const response = await axios.put(`/api/categories/${category.id}/`, category);
        commit("UPDATE_CATEGORY", response.data);
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
    async deleteCategory({ commit }, categoryId) {
      try {
        await axios.delete(`/api/categories/${categoryId}/`);
        commit("DELETE_CATEGORY", categoryId);
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    },
    // Tags
    async fetchTags({ commit }) {
      try {
        const csrfToken = getCsrfToken();
        const response = await axios.get("/api/tags/",{
          'X-CSRFToken': csrfToken,
        });
        commit("SET_TAGS", response.data);
        return response; // Return the response data to be used in the component
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    },
    async createTags({ commit }, newTags) {
      try {
        const csrfToken = getCsrfToken();  // Get the CSRF token
    
        // Send request to the backend to create new tags
        const response = await axios.post("/api/tags/", 
          { tags: newTags },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,  // Include CSRF token in headers
            },
            withCredentials: true,  // Ensure cookies are sent
          }
        );
    
        // Optionally update the state with the new tags
        commit("ADD_TAGS", response.data);  // Add new tags to state from backend response (if needed)
      } catch (error) {
        console.error("Error creating tags:", error.response?.data || error.message);
      }
    },
    async updateTag({ commit }, tag) {
      const csrfToken = getCsrfToken();
      const response = await fetch(`/api/tags/${tag.id}/`, {
        method: 'PUT',
        body: JSON.stringify(tag),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });
      const updatedTag = await response.json();
      commit('updateTag', updatedTag);
    },
    async deleteTag({ commit }, id) {
      await fetch(`/api/tags/${id}/`, { method: 'DELETE' });
      commit('deleteTag', id);
    },

    // RPS
    async createSession({ commit }) {
      const csrfToken = getCsrfToken();
      try {
        const response = await axios.post("/rps/game/", {
          'X-CSRFToken': csrfToken,
        });
        const sessionId = response.data.session_link.split("/").pop();
        commit('setSessionId', sessionId);
        window.history.pushState({}, '', `#/rps/${sessionId}`);
      } catch (error) {
        console.error("Error creating session:", error);
      }
    },
    async fetchSession({ commit }, sessionId) {
      try {
        const response = await axios.get(`/rps/game/${sessionId}/`);
        commit('setSessionData', response.data);
        commit('setSessionId', sessionId);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    },
    // async makeChoice({ commit, state }, choice) {
    //   try {
    //     const response = await axios.put(`/rps/game/${state.sessionId}/`, {
    //       player1_choice: choice,  // Adjust for player2 if needed
    //     });
    //     commit('setSessionData', response.data);
    //   } catch (error) {
    //     console.error("Error making choice:", error);
    //   }
    // },
    async makeChoice({ commit, state }, { choice, playerRole }) {
      try {
        const response = await axios.put(`/rps/game/${state.sessionId}/`, {
          player_role: playerRole,  // "player1" or "player2"
          choice: choice,  // Rock, paper, or scissors
        });
        commit('setSessionData', response.data);
      } catch (error) {
        console.error("Error making choice:", error);
      }
    },
    
    // Human History
    async fetchEvents({ commit, state }) {
      if (state.loading || state.nextPage === null) return; // Prevent duplicate requests

      commit("SET_LOADING", true);
      try {
        const response = await axios.get(`/api/events/?page=${state.nextPage}`);
        commit("ADD_EVENTS", response.data);
      } catch (error) {
        commit("SET_ERROR", "Failed to fetch events.");
        console.error(error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    //
  },
  getters: {
    isLoading: (state) => state.loading,

    isAuthenticated: (state) => !!state.authToken,
    getToken: (state) => state.authToken,
    getUser: (state) => state.user,

    blogs: (state) => state.blogs,
    totalBlogs: (state) => state.totalBlogs,
    getCategories: (state) => state.categories,
    getTags: (state) => state.tags,
    
    //RPS
    sessionComplete(state) {
      return state.sessionData && state.sessionData.result !== null;
    },
    fullSessionLink(state) {
      return `/rps/game/${state.sessionId}`;
    },
    
    //
    getHistoryEvents: (state) => state.history_events,
    getTotalHistoryEvents: (state) => state.totalHistoryEvents,


    //
  }
});
