<template>

  <FullScreenHeader />

  <ShowCase :skills="skills" />

  <div class="container px-xxl-5 px-lg-4 pt-4 pt-lg-5 pb-4">
      <!-- Page title -->
      <h1 class="ps-lg-2 ps-xxl-0 mt-2 mt-lg-0 pt-4 pb-2 mb-3 mb-xl-4">{{ pageTitle }}</h1>

      <!-- Welcome message -->
      <div class="alert alert-success d-flex fs-md mb-4" role="alert">
        <i class="bx bx-smile lead me-2 me-sm-3"></i>
        <div><strong>Welcome!</strong> {{ welcomeMessage }}</div>
      </div>

      <!-- Info alert -->
      <div class="alert alert-info d-flex fs-md mb-4" role="alert">
        <i class="bx bx-info-circle lead me-2 me-sm-3"></i>
        <div><strong>Please note:</strong> {{ alertMessage }}</div>
      </div>

      <!-- Section title -->
      <h2 class="h4 pb-1 pt-3 pt-xl-4">{{ sectionTitle }}</h2>
      <div class="my-4">
        <dl>
          <dt class="mb-1">Cool Sites with Bookmarks</dt>
          <dd class="fs-sm pb-3 border-bottom">
            A collection of useful and interesting web applications that can enhance productivity and creativity. Check out these bookmarks to discover new tools and resources!
            <ul>
              <li><a href="https://www.producthunt.com/" target="_blank" rel="noopener">Product Hunt</a> - Discover the latest in tech and apps.</li>
              <li><a href="https://www.later.com/" target="_blank" rel="noopener">Later</a> - Schedule and manage your social media posts.</li>
              <li><a href="https://www.canva.com/" target="_blank" rel="noopener">Canva</a> - Create stunning graphics and designs effortlessly.</li>
              <li><a href="https://alternativeto.net/" target="_blank" rel="noopener">AlternativeTo</a> - Find alternatives to your favorite software and web apps based on user recommendations.</li>
              <li><a href="https://archive.org/" target="_blank" rel="noopener">Internet Archive</a> - A digital library offering free universal access to books, movies, music, and more.</li>

            </ul>
            <div class="text-end">

              <a href="#" class="btn-link">More</a>
            </div>
          </dd>

          <dt class="mb-1">Cryptography Tools</dt>
          <dd class="fs-sm pb-3 border-bottom">
            Explore the fascinating world of cryptography! This section provides useful cryptographic tools, along with explanations of their functions and the history behind cryptographic practices.
            <ul>
              <li><a href="https://www.gpg4win.org/" target="_blank" rel="noopener">Gpg4win</a> - A Windows package for email and file encryption.</li>
              <li><a href="https://cryptii.com/" target="_blank" rel="noopener">Cryptii</a> - A web-based tool for encoding and decoding various formats.</li>
              <li><a href="https://www.cryptography.io/" target="_blank" rel="noopener">Cryptography.io</a> - Learn about the principles of cryptography, algorithms, and more!</li>
            </ul>
          </dd>
        </dl>
      </div>
      <p class="text-dark mb-4"><u>{{ additionalInfo }}</u></p>
    </div>
</template>

<script>
import FullScreenHeader from '@/components/FullScreenHeader.vue';
import ShowCase from '@/components/ShowCase.vue'

export default {
  data() {
    return {
      pageTitle: "Welcome to My Resource Hub",
      welcomeMessage: "This platform is designed to be a handy collection of useful resources—whether you're looking for documentation, downloadable files such as books or source code, or just want to explore cool and informative links. Feel free to explore and make the most of the resources available here.",
      alertMessage: "This page is constantly updated. Some resources are external, while others are hosted right here for your convenience.",
      sectionTitle: "Highlighted Resources",
      additionalInfo: "Explore the curated collection of development tools, educational materials, and useful documentation designed to support your projects.",
      
    };
  },
  components: {
    FullScreenHeader,
    ShowCase,
},

};
</script>

<style scoped>


</style>
