<template>
  <aside>
    <div id="docsNav" class="offcanvas-lg offcanvas-start d-flex flex-column position-fixed top-0 start-0 vh-100 border-end-lg bg-blur" style="width: 19rem; overflow-y: scroll;">
      <div class="offcanvas-header d-block d-lg-none border-bottom">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="d-lg-none mb-0">Menu</h5>
          <button type="button" class="btn-close d-lg-none" data-bs-dismiss="offcanvas" data-bs-target="#docsNav" aria-label="Close"></button>
        </div>
        <div class="list-group list-group-flush mx-n4 d-flex pee-2">
          <router-link v-for="item in navItems" :to="item.link" class="list-group-item list-group-item-action d-flex align-items-center border-0 py-2 px-4" :key="item.label">
            <i :class="item.iconClass"></i>
            {{ item.label }}
          </router-link>
        </div>
      </div>
      <div class="offcanvas-body p-4">
        <h3 class="fs-lg">Contents</h3>
        <ul class="list-group list-group-flush mx-n4 pb-2" >
          <li class="nav-item">

            <router-link v-for="content in contents" :to="content.link" class="list-group-item list-group-item-action border-0 py-2 px-4 d-flex justify-content-between" :class="{ active: content.isActive, disabled: content.missing }" :key="content.label">
              {{ content.label }}
              <span v-if="content.new" class="badge bg-info bg-opacity-10 text-info fs-xs mb-2">New</span>
              <span v-if="content.missing" class="badge bg-danger bg-opacity-10 text-danger fs-xs mb-2">Missing</span>
              <span v-if="content.broken" class="badge bg-warning bg-opacity-10 text-warning fs-xs mb-2">Broken</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="offcanvas-header border-top">
        <router-link to="/login" class="btn btn-primary w-100">
          <i class="bx bx-user fs-4 lh-1 me-1"></i>Login
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      logoSrc: "../assets/logo.png",
      brandName: "D1m",
      // navItems: [
      //   { label: "Preview", link: "/index", iconClass: "bx bx-desktop fs-lg opacity-80 me-2" },
      //   { label: "UI Kit (Components)", link: "/components/typography", iconClass: "bx bx-layer fs-lg opacity-80 me-2" }
      // ],
      contents: [
        { label: "Typography", link: "/ui_kit/typography"},
        { label: "Accordion", link: "/ui_kit/accordion" },
        { label: "Alerts", link: "/ui_kit/alerts" },
        { label: "Audio Player", link: "/ui_kit/audio-player", missing:true, },
        { label: "Badges", link: "/ui_kit/badges" },
        { label: "Blog Components", link: "/ui_kit/blog-components", missing:true, },
        { label: "Breadcrumb", link: "/ui_kit/breadcrumb", missing:true, },
        { label: "Buttons", link: "/ui_kit/buttons" },
        { label: "Button Group", link: "/ui_kit/button-group" },
        { label: "Cards", link: "/ui_kit/cards", missing:false, },
        { label: "Carousel (Slider)", link: "/ui_kit/carousel" },
        { label: "Code", link: "/ui_kit/code" },
        { label: "Collapse", link: "/ui_kit/collapse" },
        { label: "Dropdowns", link: "/ui_kit/dropdowns" },
        { label: "Forms", link: "/ui_kit/forms" },
        { label: "Gallery (Lightbox)", link: "/ui_kit/gallery", missing:true, },
        { label: "Icons", link: "/ui_kit/icon-font" },
        { label: "Images", link: "/ui_kit/images" },
        { label: "Image Comparison Slider", link: "/ui_kit/image-comparison-slider" },
        { label: "Input Group", link: "/ui_kit/input-group" },
        { label: "List Group", link: "/ui_kit/list-group" },
        { label: "Modal", link: "/ui_kit/modal" },
        { label: "Navbar", link: "/ui_kit/navbar" },
        { label: "Offcanvas", link: "/ui_kit/offcanvas" },
        { label: "Pagination", link: "/ui_kit/pagination" },
        { label: "Parallax", link: "/ui_kit/parallax" },
        { label: "Pills", link: "/ui_kit/pills" },
        { label: "Placeholders", link: "/ui_kit/placeholders" },
        { label: "Popovers", link: "/ui_kit/popovers" },
        { label: "Portfolio Components", link: "/ui_kit/portfolio-components" },
        { label: "Pricing", link: "/ui_kit/pricing" },
        { label: "Progress Bars", link: "/ui_kit/progress-bars", missing: true, },
        { label: "Social Buttons", link: "/ui_kit/social-buttons" },
        { label: "Spinners", link: "/ui_kit/spinners" },
        { label: "Steps", link: "/ui_kit/steps" },
        { label: "Tabs", link: "/ui_kit/tabs" },
        { label: "Tables", link: "/ui_kit/tables" },
        { label: "Team", link: "/ui_kit/team" },
        { label: "Testimonials / Reviews", link: "/ui_kit/testimonials" },
        { label: "Toasts", link: "/ui_kit/toasts" },
        { label: "Tooltips", link: "/ui_kit/tooltips" },
        { label: "Video Popup", link: "/ui_kit/video-popup" }
      ]
    };
  }
};
</script>

<style scoped>
/* Add necessary styles here */
</style>
