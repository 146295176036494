<template>
	<section class="container-fluid my-5 py-5">
		<div class="container px-4">
			<div class="row">

				<h2 class="text-center mb-4">My Expertise</h2>
				<div class="mb-4">
					<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur vel enim quos esse magnam
						saepe aliquid nam nemo voluptatem id eaque, necessitatibus expedita iusto veritatis vitae officiis
						ab debitis ducimus.
					</p>
				</div>

				<div class="col-md-6 col-lg-4 mb-4" ref="card" v-for="(skill, index) in skills" :key="index" @mousemove="handleMouseMove($event, index)">
					<div class="card border-0  rounded-5 skill-card" :style="skill.styleObject">
						<div class="card-body">
							<h5 class="card-title">{{ skill.title }}</h5>
							<p class="card-text">{{ skill.description }}</p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>
</template>

<script>
export default {
	data(){
		return {
			skills: [
				{
				title: "Software Consulting",
				description: "We gather and analyze your business needs, conceptualize software solutions, and ensure you get the best value from its development."
				},
				{
				title: "Web Development",
				description: "Specializing in web development since 1999, we create robust customer-facing and corporate web-based applications."
				},
				{
				title: "Mobile Development",
				description: "We develop high-quality mobile applications tailored to meet your specific user, business, industry, and vertical needs."
				},
				{
				title: "Desktop Development",
				description: "Our team crafts desktop applications that offer high performance and deep integration with underlying hardware."
				},
				{
				title: "Cloud Solutions",
				description: "Leverage cloud computing for enhanced scalability and flexibility in managing infrastructure."
				},
				{
				title: "Database Management",
				description: "We provide robust database management solutions to organize, store, and analyze large sets of data efficiently."
				},
				{
				title: "SaaS Development",
				description: "Develop scalable and versatile SaaS solutions that grow with your business needs."
				},
				{
				title: "API Engineering & Software Integration",
				description: "We create and integrate APIs to streamline and enhance the functionality of your software systems."
				},
				{
				title: "Software Modernization",
				description: "Transform your legacy systems with modern technology to improve efficiency and productivity."
				}
			]
		}
	},
	methods: {
		handleMouseMove(event) {
			// const container = this.$el.querySelector('.container'); // Assumed container
			// const containerRect = container.getBoundingClientRect();

			this.skills.forEach((skill, index) => {
				const card = this.$refs.card[index];
				const cardRect = card.getBoundingClientRect();
				const x = event.clientX - (cardRect.left + cardRect.width / 2);
				const y = event.clientY - (cardRect.top + cardRect.height / 2);
				const angle = Math.atan2(y, x) * 180 / Math.PI;
				skill.styleObject = { '--rotate': `${angle}deg` };
			});
		}
	},
}
</script>

<style scoped>
.container-fluid {
  /* background-image: url('@/assets/img/abstract/blackwhite.jpg') !important; */
}
.skill-card {
	color: var(--si-body-color);
	/* background: linear-gradient(99deg, rgba(var(--si-primary-rgb), 0.27) 0%, rgba(var(--si-info-rgb), 0.23) 50%); */
	background: transparent;
	z-index: 3;
	position: relative;
	overflow: hidden;
	/* color: snow !important; */
}
.skill-card h5{
	/* color: snow !important; */
	/* text-shadow: 1px 0px 2px rgba(0,0,0,0.2); */
}

.skill-card .card-body {
  min-height: 180px;
  position: relative;
}

.skill-card:hover::before {
  opacity: 1;
}


.skill-card:hover {
  /* color: rgb(88 199 250 / 100%); */
  transition: color 3s;
}
.skill-card:hover:before,
.skill-card:hover:after {
  animation: none;
  /* opacity: 0; */
}

.skill-card::after {
	content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate, 0deg),
    rgba(var(--si-info-rgb), 0.5) -100%, 
    /* rgba(var(--si-dark-rgb), 0.4) */
	transparent 30%
  );
  position: absolute;

  backdrop-filter: blur(3px);
  z-index: -3;
}
.skill-card:hover .skill-card::after {
	
	content: "";
	width: 214%;
	height: 102%;
}




</style>