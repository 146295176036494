<template>
    <header class="header navbar navbar-expand-lg shadow-sm fixed-top" :class="{ 'bg-blur': !isOffcanvasShown }">
      <div class="container px-3">
        <router-link to="/" class="navbar-brand p-0 m-0 pe-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 80" width="100" height="30" class="logo">
                <text x="0" y="70" class="red-shadow">D1M</text>
                <text x="0" y="70" class="cyan-shadow">D1M</text>
                <text x="0" y="70" class="main-text">D1M</text>
            </svg>

          
        </router-link>
        

        <div id="navbarNav" class="offcanvas offcanvas-end" ref="offcanvas">
            <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title">Menu</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li v-for="(menuItem, index) in menuItems" :key="index" class="nav-item dropdown">
                        
                        <router-link v-if="!menuItem.dropdown" :to="menuItem.url" class="nav-link" :class="menuItem.dropdown ? 'dropdown-toggle' : ''" @click="closeOffcanvas()">{{ menuItem.title }}</router-link>
                        
                        <a href="#!" v-else class="nav-link" :class="menuItem.dropdown ? 'dropdown-toggle' : ''" data-bs-toggle="dropdown" @click="toggleDropdown(menuItem)" :aria-expanded="menuItem.isOpen ? 'true' : 'false'">{{ menuItem.title }}</a>
                        
                        <div v-if="menuItem.dropdown" class="p-0" :class="{ 'dropdown-menu': true, 'show': menuItem.isOpen }">
                            <div class="d-lg-flex">
                                <div v-if="menuItem.image" class="mega-dropdown-column d-flex justify-content-center align-items-center rounded-3 rounded-end-0 px-0"
                                    style="margin: -1px; background-color: #f3f6ff;">
                                    <img :src="menuItem.image" alt="Landings">
                                </div>
                                <div v-for="(section, sectionIndex) in menuItem.sections" :key="sectionIndex" class="mega-dropdown-column pt-lg-3 pb-lg-4">
                                    <ul class="list-unstyled mb-0">
                                        <li v-if="section.sectionTitle" class="px-3 mb-2">{{ section.sectionTitle }}</li>
                                        <li v-for="(link, linkIndex) in section.links" :key="linkIndex">
                                            <router-link :to="link.url" class="dropdown-item d-flex align-items-center" @click="closeOffcanvas()">
                                                {{ link.title }}
                                                <span v-if="link.new" class="badge bg-success ms-2">New</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="dropdown" v-if="isAuthenticated">
            <a class="btn btn-sm btn-secondary dropdown-toggle m-0 me-md-2 p-2" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <img :src="user.profilePic || defaultAvatar" alt="User Avatar" class="user-avatar me-2 p-0 my-0" />
                {{ user.username }}
            </a>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li><router-link to="/profile" class="dropdown-item">Profile</router-link></li>
                <li><router-link to="/settings" class="dropdown-item">Settings</router-link></li>
                <li><router-link to="/" @click="logout" class="dropdown-item">Logout</router-link></li>
            </ul>
        </div>
            

        <div v-else>
            <router-link to="/login" class="nav-link1 btn btn-sm btn-outline-primary text-sm mx-3">Login</router-link>
        </div>


        <div class="form-check form-switch mode-switch pe-2 ms-auto mx-2" data-bs-toggle="mode">
            <input class="form-check-input" type="checkbox" id="theme-mode" :checked="isDarkMode" @change="toggleTheme">
            <label class="form-check-label d-none d-sm-block" for="theme-mode">Light</label>
            <label class="form-check-label d-none d-sm-block" for="theme-mode">Dark</label>
        </div>



        <button type="button" class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

      </div>
  </header>
</template>

<script>

import { Offcanvas } from 'bootstrap';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            showLoginDropdown: false,
            showDropdown: false,
            defaultAvatar: 'https://www.pphfoundation.ca/wp-content/uploads/2018/05/default-avatar.png',
            bsOffcanvas: null,
            isOffcanvasShown: false,
            menuItems: [
                {
                    title: 'Home',
                    dropdown: false,
                    url: '/'
                },
                
                {
                    title: 'Information',
                    dropdown: true,
                    sections: [
                        {
                            sectionTitle: 'Useful & Interesting stuff',
                            links:[
                                { title: 'Bookmarks', url: '/bookmarks' },
                                { title: 'Great Minds', url: '/great-minds' },
                                { title: 'Quotes', url: '/quotes' },
                                { title: 'Human History', url: '/human-history' },
                                { title: 'Scroll to the abyss', url: '/scroll-to-the-abyss' },
                                { title: 'Solar System', url: '/solar-system' },
                                
                                
                            ],
                        },
                        {
                            sectionTitle: 'Calculators',
                            links:[
                                


                                { title: 'Sleep calculator', url: '/sleep-calculator' },
                                { title: 'Saving Calculator', url: '/saving-calculator' },
                                { title: 'BMI Calculator', url: '/bmi-calculator' },

                                { title: 'Flash Cards', url: '/flash-cards' },
                                { title: 'Hourglass', url: '/hourglass' },

                                { title: 'Particle Sphere', url: '/particle-sphere' },
                                { title: 'Window Designer', url: '/window-designer' },


                            ],
                        },
                        {
                            links:[
                                { title: 'Graphs', url: '/graphs' },
                                { title: 'Rubix Cube Algorithms', url: '/rubix-cube-algorithms' },
                                { title: 'Rubix Cube Stopwatch', url: '/rubix-cube-stopwatch' },
                            ],
                        },
                    ],
                },
                {
                    title: 'Games',
                    dropdown: true,
                    sections: [
                        {
                            sectionTitle: 'Logic & Thinking',
                            links:[
                                // { title: 'Logic',},
                                { title: 'Catch the thief', url: '/thief' },
                                { title: 'Cabbage, Sheep and Wolf', url: '/cabbage-sheep-wolf' },
                                { title: 'Sudoku', url: '/sudoku' },
                                { title: 'Crack the code', url: '/crack-the-code' },
                                { title: 'Sequence Memory', url: '/sequence-memory' },
                                { title: 'Reaction Time', url: '/games/reaction-time' },
                                { title: 'Aim trainer', url: '/games/aim-trainer' },
                                { title: 'Memory Game', url: '/memory-game' },
                                { title: 'Speed typing', url: '/speed-typing' },
                                

                            ],
                        },
                        {
                            sectionTitle: 'Luck & Chance',
                            links:[

                                { title: 'Slot Machine', url: '/slot-machine' },
                                { title: 'Roulette', url: '/roulette' },
                                { title: 'Black Jack', url: '/black-jack',new: true },
                                { title: 'World Map', url: '/world-map' },

                                { title: 'Language Game', url: '/language-game' },

                                { title: 'Rock Paper Scissors', dropdown: false, url: '/rps/create',},
                                { title: 'Jigsaw Puzzle', url: '/games/jigsaw-puzzle' },
                            ],
                        },
                    ],
                },
                {
                    title: 'Developer',
                    dropdown: true,
                    sections: [
                        {
                            links:[

                                { title: 'ADT Time Complexity', url: '/adt-time-complexity' },
                                { title: 'Binary Search Tree', url: '/binary-search-tree' },
                                { title: 'Graphs & Trees', url: '/ads/graphs' },
                                { title: 'Sorting Algorithms', url: '/sorting-algorithms' },
                                { title: 'CSS Backgrounds', url: '/css-backgrounds' },
                                { title: 'HTTP Status Codes', url: '/http-status-codes' },
                                { title: 'Bootstrap Cheatsheet', url: '/bootstrap-cheatsheet' },
                                
                                { title: 'Tmux Cheatsheet', url: '/tmux-cheatsheet' },
                                { title: 'Nginx Cheatsheet', url: '/nginx-cheatsheet' },

                            ],
                        },
                        {
                            links:[
                                { title: 'Developer Checklist', url: '/developer-checklist' },
                                { title: 'Minifier', url: '/minifier' },
                                { title: 'CSS Glassmorophism', url: '/css-generator' },
                                
                                { title: 'XOR Cipher', url: '/xor-cipher' },
                                { title: 'Caesar Cipher', url: '/caesar-cipher' },

                                { title: 'Binary Operations', url: '/binary-operations' },

                            ],
                        },
                        
                    ],
                },
                {
                    title: 'Hidden',
                    dropdown: true,
                    sections: [
                        {
                            links:[

                                { title: 'Message Faker', url: '/chat-ui' },
                                

                            ],
                        },
                        
                    ],
                },
                {
                    title: 'Blog',
                    dropdown: false,
                    url: '/blog'
                },
                {
                    title: 'UI Kit',
                    dropdown: false,
                    url: '/ui_kit/typography'
                },
            ]
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'getToken']),
        user() {
            return this.$store.state.user || { username: 'Guest' };
        },
    },
    methods:{
        toggleTheme() {
            this.isDarkMode = !this.isDarkMode;
            if (this.isDarkMode) {
                document.documentElement.setAttribute('data-bs-theme', 'dark');
                localStorage.setItem('theme', 'dark');
            } else {
                document.documentElement.setAttribute('data-bs-theme', 'light');
                localStorage.setItem('theme', 'light');
            }
        },
        toggleOffcanvas() {
            // This method toggles the offcanvas and updates the reactive property based on the presence of the 'show' class
            this.$nextTick(() => {
                const offcanvasElement = this.$refs.offcanvas;
                this.isOffcanvasShown = offcanvasElement.classList.contains('show');

                // Optionally, add a listener for offcanvas events if toggling is handled outside Vue
                offcanvasElement.addEventListener('shown.bs.offcanvas', () => {
                    this.isOffcanvasShown = true;
                });
                offcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
                    this.isOffcanvasShown = false;
                });
            });
        },
        closeOffcanvas() {
            this.bsOffcanvas.hide();  // Close the offcanvas menu
            const backdrops = document.querySelectorAll('.offcanvas-backdrop');
            backdrops.forEach(backdrop => backdrop.remove());
        },
        toggleDropdown(item) {
            // This manually toggles the open state and updates the aria-expanded attribute
            item.isOpen = !item.isOpen;
        },
        handleOutsideClick(event) {
            if (!event.target.matches('.dropdown-toggle')) {
                this.menuItems.forEach(item => item.isOpen = false);
            }
        },
        ...mapActions(['logout']),
        toggleLoginDropdown() {
            console.log('toggle showLoginDropdown', this.showLoginDropdown);
            
            this.showLoginDropdown = !this.showLoginDropdown;
        },
        async logout() {
            this.$store.dispatch('logout');
            this.$router.push('/login');
        },
    },
    watch: {
        isDarkMode(newValue) {
            this.themeLabel = newValue ? "Dark" : "Light";
        },
    },
    
    created() {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            this.isDarkMode = true;
        }else{
            this.isDarkMode = false;
        }
        
        if (this.isAuthenticated) {
            this.$store.dispatch("fetchUser");
        }
    },
    mounted() {
        // Setup listeners once the component is mounted
        this.toggleOffcanvas(); // Initial check
        // document.addEventListener('click', this.handleOutsideClick);
        this.bsOffcanvas = new Offcanvas(this.$refs.offcanvas, {
            backdrop: true,
        });
    },
    beforeUnmount() {
        if (this.bsOffcanvas) {
            this.bsOffcanvas.dispose();  // Clean up the offcanvas instance
        }
    }
    
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900');
    .logo text {
        font-family: "Montserrat", sans-serif;
      font-size: 90px;
      font-weight: bold;
      transition: transform 0.5s ease;
    }

    .user-avatar{
        width: 24px;
        border-radius: 50%;
    }

    .red-shadow {
      fill: rgba(var(--si-danger-rgb), 0.65);
      transform: translate(3px, 3px);
      transition: transform 0.5s ease;
    }

    .cyan-shadow {
      fill: rgba(var(--si-info-rgb), 0.65);
      transform: translate(-3px, -3px);
      transition: transform 0.5s ease;
    }

    [data-bs-theme=light] .main-text {
      fill: black;
    }
    [data-bs-theme=dark] .logo .main-text {
      fill: white;
    }
    svg .main-text{
        animation: glitch 5s steps(100) infinite;
        transition: transform 1s ease;
    }

    svg:hover .main-text {
        animation: none;
        font-size: 92px;
    }
    
    svg:hover .red-shadow {
      transform: translate(9px, 9px);
    }

    svg:hover .cyan-shadow {
      transform: translate(-9px, -9px);
    }

    @keyframes glitch { 
        0% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        1% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        2% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        3% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        4% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        5% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        6% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        7% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        8% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        9% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        10% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        11% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        12% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        13% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        14% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        15% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        16% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        17% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        18% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        19% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        20% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        21% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        22% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        23% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        24% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        25% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        26% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0.5px);}
        27% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0.5px);}
        28% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        29% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        30% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        31% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        32% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        33% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        34% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        35% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0.5px);}
        36% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0.5px);}
        37% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0.5px);}
        38% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        39% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        40% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        41% { text-shadow: 45px 0 0 #0c33f5, -45px 0 0 lime; filter: blur(0);}
        42% { text-shadow: 0 0 0 #0c33f5, 0 0 0 lime; filter: blur(0);}
        43% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        44% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        45% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        46% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        47% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0.5px);}
        48% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        49% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        50% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        51% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        52% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        53% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        54% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        55% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0.5px);}
        56% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0.5px);}
        57% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0.5px);}
        58% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        59% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        60% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        61% { text-shadow: 30px 0 0 red, -30px 0 0 lime; filter: blur(0);}
        62% { text-shadow: 0 0 0 red, 0 0 0 lime; filter: blur(0);}
        63% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5; filter: blur(0);}
        64% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5; filter: blur(0);}
        65% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5; filter: blur(0);}
        66% { text-shadow: 1px 0 0 red, -1px 0 0 #0c33f5; filter: blur(0.5px);}
        67% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0.5px);}
        68% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        69% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        70% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        71% { text-shadow: 50px 0 0 red, -50px 0 0 #0c33f5; filter: blur(0);}
        72% { text-shadow: 0 0 0 red, 0 0 0 #0c33f5; filter: blur(0);}
        73% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        74% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        75% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        76% { text-shadow: 3px 0 0 red, -3px 0 0 #0c33f5; filter: blur(0);}
        77% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        78% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        79% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        80% { text-shadow: -3px 0 0 red, 3px 0 0 #0c33f5; filter: blur(0);}
        81% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        82% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0);}
        83% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        84% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        85% { text-shadow: 1px 0 0 red, -1px 0 0 lime; filter: blur(0.5px);}
        86% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0.5px);}
        87% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0.5px);}
        88% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        89% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        90% { text-shadow: -3px 0 0 red, 3px 0 0 lime; filter: blur(0);}
        91% { text-shadow: 60px 0 0 lime, -60px 0 0 #0c33f5; filter: blur(0);}
        92% { text-shadow: 0 0 0 lime, 0 0 0 #0c33f5; filter: blur(0);}
        92% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        93% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        94% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        95% { text-shadow: 0.8px 0 0 #0c33f5, -0.8px 0 0 lime; filter: blur(0);}
        96% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        97% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        98% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        99% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
        100% { text-shadow: -3px 0 0 #0c33f5, 3px 0 0 lime; filter: blur(0);}
    }


  </style>