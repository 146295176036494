<template>
  <router-view/>

    <a class="btn-scroll-top" :class="showButton ? 'show' : ''" @click="scrollToTop" aria-label="Scroll back to top" >
      <span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
      <i class="btn-scroll-top-icon bx bx-chevron-up"></i>
    </a>
    <CookieConsent />
</template>

<script>
import CookieConsent from "./components/CookieConsent.vue";

// import { mapState } from 'vuex';

export default {
    name: "App",
    data() {
        return {
            // currentColor: '#4044ee',
            isDarkMode: document.documentElement.getAttribute("data-bs-theme") === "dark",
            // theme: 'light',
            showButton: false,
            scrollOffset: 450,
        };
    },
    computed: {},
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    created() {
        this.$store.dispatch("loadAuth");
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollPercent = (document.body.scrollTop + document.documentElement.scrollTop) /
                (document.documentElement.scrollHeight - document.documentElement.clientHeight);
            const draw = this.length * scrollPercent;
            this.dashoffset = this.length - draw;
            if (window.pageYOffset > this.scrollOffset) {
                this.showButton = true;
            }
            else {
                this.showButton = false;
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        toggleTheme() {
            console.log("new theme:", newTheme);
            const newTheme = this.isDarkMode ? "light" : "dark";
            document.documentElement.setAttribute("data-bs-theme", newTheme);
            this.isDarkMode = !this.isDarkMode;
            localStorage.setItem("theme", newTheme);
        }
    },
    components: { CookieConsent }
};
</script>

<style>
  #app{
    height: 100% !important;
  }
</style>
