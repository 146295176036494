<template>
    <NavBarVue></NavBarVue>
    
    <main :class="{'pt-5 mt-5': !isHomepage && !isScrollToAbyss}">
      <router-view />
    </main>
    <!-- <FooterBlock></FooterBlock> -->
  </template>
  
  <script>
  // import FooterBlock from "../components/FooterBlock.vue";
  import NavBarVue from "../components/NavbarDefault.vue";
  
  export default {
    name: 'App',
    components: {
      NavBarVue,
      // FooterBlock,
    },
    computed: {
      isHomepage() {
        return this.$route.path === '/';
      },
      isScrollToAbyss() {
        return this.$route.path === '/scroll-to-the-abyss'; 
      },
      
    }
  };
  </script>
  
  
  <style>
  </style>
  